<template>
  <div class="row mt-60">
    <loader-component v-if="loading" />
    <div class="col-md-12">
      <ul class="new-service-list">
        <li><a href="javascript:void(0)" @click="filter.doctor_id = '';getReports();" :class="filter.doctor_id == '' ? 'active' : ''">All</a></li>
        <li v-for="doctor in doctors" :key="doctor.id">
          <a href="javascript:void(0)" :class="filter.doctor_id == doctor.id ? 'active' : ''" @click="filter.doctor_id = doctor.id;getReports();" >{{ (doctor.sub_type == 'doctor' || doctor.sub_type == 'dentist')? 'Dr. ':'' }} {{ doctor.first_name }}</a>
        </li>
      </ul>
    </div>

    <div class="col-md-5">
      <div class="c-card">
        <div class="filter-section">
          <form>
            <ul class="new-center-list">
              <li>
                <a
                  href="javascript:void(0)"
                  :class="filter.center == '' ? 'active' : ''"
                  @click="
                    filter.center = '';
                    showCenterFilter = false;
                    getReports();
                  "
                  >All</a
                >
              </li>
              <li v-for="(item, key) in centers" :key="key">
                <a
                  href="javascript:void(0)"
                  :class="item.short_form == filter.center ? 'active' : ''"
                  @click="
                    filter.center = item.short_form;
                    showCenterFilter = false;
                    getReports();
                  "
                  >{{ item.short_form }}</a
                >
              </li>
            </ul>

            <ul class="tab-list">
              <li
                @click="filter.period = 'today'; getReports();"
                :class="{ active: filter.period == 'today' }"
              >
                Today
              </li>
              <li
                @click="filter.period = 'tomorrow'; getReports();"
                :class="{ active: filter.period == 'tomorrow'  }"
              >
                Tomorrow
              </li>
              <li  @click="filter.period = 'next_week'; getReports();"
                :class="{ active: filter.period == 'next_week'  }">
                Next 1 Wk</li>
            </ul>

            <div class="c-tab-content" v-if="activeTab == 'patient'">
              <ul
                class="cons-tab-item"
              >
                <li>
                  <span class="service-name">Consultation</span>
                </li>
                <li>
                  <span class="pt-count">{{ services.consultation }}</span>
                  <span class="pt-label">Pt.</span>
                </li>
              </ul>

              <ul class="c-tab-item" v-if="services.zcf">
                <li>
                  <span class="service-name">Zero Clubfoot</span>
                </li>

                <li>
                  <span class="service-name">Casting</span>
                </li>

                <li>
                  <span class="pt-count">{{ services.zcf.casting?services.zcf.casting.patient_count:0 }}</span>
                  <span class="pt-label">Pt.</span>
                </li>

                <li>
                  <span class="pt-count">{{ services.zcf.casting?services.zcf.casting.foot_count:0 }}</span>
                  <span class="pt-label">Ft.</span>
                </li>

                <li>
                  <span class="service-name">Procedure</span>
                </li>

                <li>
                  <span class="pt-count">{{ services.zcf.procedure.patient_count }}</span>
                  <span class="pt-label">Pt.</span>
                </li>

                <li>
                  <span class="pt-count">{{ services.zcf.procedure.foot_count }}</span>
                  <span class="pt-label">Ft.</span>
                </li>

                <li>
                  <span class="service-name">Follow-up</span>
                </li>

                <li>
                  <span class="pt-count">{{ services.zcf.followup.patient_count }}</span>
                  <span class="pt-label">Pt.</span>
                </li>

                <li>
                  <span class="pt-count">{{ services.zcf.followup.foot_count }}</span>
                  <span class="pt-label">Ft.</span>
                </li>
              </ul>

              <ul class="c-tab-item pht" v-if="services.pht">
                <li>
                  <span class="service-name">Physiotherapy</span>
                </li>

                <li>
                  <span class="service-name">Follow-up</span>
                </li>

                <li>
                  <span class="pt-count">{{ services.pht.followup }}</span>
                  <span class="pt-label">Pt.</span>
                </li>

                <li>
                  <span class="pt-count">&nbsp;</span>
                  <span class="pt-label">&nbsp;</span>
                </li>

                <li>
                  <span class="service-name">Session</span>
                </li>

                <li>
                  <span class="pt-count">{{ services.pht.session }}</span>
                  <span class="pt-label">Pt.</span>
                </li>

                <li>
                  <span class="pt-count">&nbsp;</span>
                  <span class="pt-label">&nbsp;</span>
                </li>
              </ul>
            </div>

            <div class="total-content">
              <ul>
                <li>
                  Total
                </li>
                <li>
                  <span class="pt-count" v-if="services.pht && services.zcf">{{ services.pht.session + services.pht.followup + services.zcf.followup.patient_count + services.zcf.procedure.patient_count + services.zcf.casting.patient_count + services.consultation }}</span>
                  <span class="pt-label">Pt.</span>
                </li>
                <li>
                  <span class="pt-count" v-if="services.zcf">{{ services.zcf.casting.foot_count + services.zcf.procedure.foot_count + services.zcf.followup.foot_count }}</span>
                  <span class="pt-label">Ft.</span>
                </li>
              </ul>
            </div>
          </form>

        </div>
       
      </div>
    </div>

    <div class="col-md-7">
      <div class="c-card p-7">
        <div class="patient-income-table tableFixHead">
          <table class="timeline-tbl">
            <thead>
              <tr>
                <th>My Patients</th>
                <th>Service</th>
                <th>Status</th>
                <th>Center</th>
                <th>Date & Time</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in table_data" :key="index">
                <td style="width: 220px">
                  <div class="patient-card">
                    <img :src="userPhoto(item.image)" alt="Orko" v-if="item.image">
                    <img :src="require('@/assets/img/blank.png')" alt="Orko" v-else>
                    <div>
                      <p class="mb-0 mt-0"><b>{{ item.patient_name }}</b></p>
                      <p class="mb-0 mt-0">Age: {{ item.age }}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <span v-if="item.appointment_type == 'booked_appointment'">Consultation</span>
                  <span v-else-if="item.appointment_type == 'therapy_appointment' || item.appointment_type == 'therapy_session'">Physiotherapy</span>
                  <span v-else-if="item.appointment_type == 'clubfoot_appointment' || item.appointment_type == 'Casting' || item.appointment_type == 'Procedure'">Zero Clubfoot</span>
                </td>
                <td>
                  <span v-if="item.is_followup">Follow-up</span>  
                  <span v-else-if="item.appointment_type == 'booked_appointment'">N/A</span>  
                  <span v-else-if="item.appointment_type == 'clubfoot_appointment' || item.appointment_type == 'therapy_appointment'">Consultation</span>  
                  <span v-else-if="item.appointment_type == 'therapy_session'">Session</span>  
                  <span v-else>{{ item.appointment_type }}</span>  
                </td>
                <td>{{ getCenterName(item.institute_id) }}</td>
                <td>{{ dateFormat(item.schedule_date) }}{{ item.schedule_started_at?', '+timeFormat(item.schedule_started_at):'' }}</td>
              </tr>

            </tbody>
            <tfoot>
              <tr>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "OCCAppointmentReportView",
  data() {

    return {
      loading: false,
      activeTab: "patient",
      filter: {
        period: "today",
        doctor_id: "",
        center: ""
      },
      table_data: [],
      services: [],
      targets: [],
      age_wise_service: {
        treatment: [],
      },
      lastMonthData: {},
      showMonthFilter: false,
      showYearFilter: false,
      showCenterFilter: false,
      showPatientFilter: false,
      centers: [],
      doctors:[]
    };
  },
  created() {
    // this.filter.month = moment().format("MMM");
    // this.setYears();
    this.getReports();
    this.centerList();
    localStorage.setItem("tabIndex", 4);
    this.$root.$emit("call-active-menu", 4);
  },
  methods: {
    dateFormat(date) {
      if(date == moment().format('YYYY-MM-DD')) {
        return 'Today';
      } 
      return moment(date).format('D MMM YYYY');
    },
    timeFormat(time) {
      // moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(time, 'H:mm:ss').format('h:mm a');
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      }
    },
    getCenterName(centerId) {
      let center = this.centers.find(item=>item.id == centerId);
      if(center) {
        return center.short_form;
      }
    },
   
  
    getReports() {
      this.loading = true;
      let selectedCenter = null;
      if (this.filter.center) {
        selectedCenter = this.centers.find(
          (item) => item.short_form == this.filter.center
        );
      }
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL
          }/api/v2/report/orkocenter/new/occ/appointment?api_token=${localStorage.getItem(
            "api_token"
          )}${
            selectedCenter ? "&institute_id=" + selectedCenter.id : ""
          }
              ${
                this.filter.doctor_id
                  ? "&doctor_id=" + this.filter.doctor_id
                  : ""
              }
              &period=${this.filter.period}`
        )
        .then((res) => {
          this.table_data = res.data.data;
          this.services = res.data.service_counts;
          this.doctors = res.data.doctors;

          this.loading = false;
        });
    },
    sortService(services, orders) {
      let newArr = [];
      for (let i = 0; i < orders.length; i++) {
        if (services.some((e) => e.product_type === orders[i])) {
          newArr.push(services.find((e) => e.product_type === orders[i]));
        }
      }

      for (let k = 0; k < services.length; k++) {
        if (!newArr.some((e) => e.product_type === services[k].product_type)) {
          newArr.push(
            services.find((e) => e.product_type === services[k].product_type)
          );
        }
      }
      return newArr;
    },
    centerList() {
      axios
        .get(
          `${
            process.env.VUE_APP_URL
          }/api/v1/institute/list?type=orko&api_token=${localStorage.getItem(
            "api_token"
          )}`
        )
        .then((response) => {
          if (response.data.status_code == "500") {
            this.$router.push("/access-denied");
          } else {
            // this.loading = false
            this.centers = response.data.data;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.c-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px; /* 5px rounded corners */
  min-height: 200px;
  background-color: #fff;
  width: 100%;
  float: left;

  .filter-list {
    width: 100%;
    float: left;
    list-style-type: none;
    margin-top: 20px;
    margin-bottom: 20px;

    li {
      width: 33.333333%;
      float: left;
      padding: 0px 10px 0;
      border-right: 2px solid #002170;

      &:last-of-type {
        border-right: none;
        padding: 0;
      }

      select {
        border: none;
        outline: none;
        scroll-behavior: smooth;
        -webkit-appearance: listbox !important;

        color: #002170;
        font-size: 15px;
        font-weight: 600;
        font-family: Arial;
        padding: 0 12px 0 8px;
        height: 22px;
      }
    }
  }

  .target-content {
    width: 100%;
    float: left;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 15px; /* 5px rounded corners */
    min-height: 200px;
    background-color: #00579b;
    padding: 25px;

    h2 {
      color: #f9a91a;
      font-family: Arial;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 15px;
    }

    ul {
      list-style-type: none;
      margin-bottom: 20px;
      width: 100%;
      float: left;

      li {
        width: 100%;
        float: left;

        .l-label {
          width: 45%;
          float: left;
        }

        .r-label {
          text-align: right;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          font-family: Arial;

          .achieved {
            color: #f9a91a;
          }
        }

        label {
          color: #fff;
          font-size: 18px;
          font-family: Arial;
          font-weight: 600;
        }
      }
    }
  }

  .timeline-tbl {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    float: left;
    .expanded-row {
      box-shadow: 0 0 0 1px #aaa;
      border-radius: 20px;
    }

    tr {
      th {
        color: #002147;
        border-bottom: 1px solid #a0a0a1;
        padding-bottom: 10px;
        text-align: center;
        font-size: 16px;
      }
    }

    tbody {
      tr {
        &.expanded-total-row {
          border-top: 1px solid #aaa;
          td {
            ul {
              margin: 6px 0;
              list-style-type: none;
              li {
                line-height: 1.6;
                &:last-of-type {
                  font-size: 13px;
                  span {
                    font-size: 13px;
                    i {
                      font-size: 13px;
                    }
                  }
                }
              }
            }
          }
        }
        td {
          text-align: center;
          font-size: 14px;
          line-height: 2.5;
          color: #212120;
        }

        &:first-of-type {
          td {
            padding-top: 10px;
          }
        }
      }
    }
  }

  .summary-wrapper {
    width: 100%;
    float: left;
    background-color: #00579b;
    padding: 8px 30px;
    border-radius: 20px;
    margin-top: 30px;

    .summary-tbl {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      float: left;

      tr {
        th {
          color: #fff;
          font-size: 17px;
          padding-bottom: 5px;
        }

        td {
          font-size: 15px;
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
}

.brd-15 {
  border-radius: 15px;
}

.ftb-15 {
  font-size: 15px;
  font-weight: 600;
}

.up-text {
  color: #00cd32;
  font-size: 15px;
  font-weight: 600;

  i {
    color: #00cd32;
    font-size: 13px;
  }
}

.down-text {
  color: red;
  font-size: 15px;
  font-weight: 600;

  i {
    color: red;
    font-size: 13px;
  }
}

.coll-btn {
  color: #002170;
  font-size: 15px;
  font-weight: 600;
  font-family: Arial;
  padding: 0 12px 0 8px;
  height: 22px;

  background-color: transparent;
  border: none;

  &:active {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }
}

.month-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #ebebeb;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }

    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9),
    &:nth-child(13) {
      a {
        background-color: #aaaaaa;
        color: #fff;
        &.active {
          background-color: #f9a81a;
          font-weight: 600;
        }
      }
    }
  }
}

.year-selection-list {
  width: 100%;

  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  li {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      background-color: #d9d4d4;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;
      margin: 5px;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }

    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(9),
    &:nth-child(13) {
      a {
        background-color: #aaaaaa;
        color: #fff;
        &.active {
          background-color: #f9a81a;
          font-weight: 600;
        }
      }
    }
  }
}

.center-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 25%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #ebebeb;
      padding: 7px 25px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }
  }
}

.new-service-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  // margin: 0;
  li {
    width: 140px;
    float: left;
    text-align: center;
    margin-bottom: 10px;
    a {
      background-color: #fff;
      width: 95%;
      display: block;
      padding: 5px;
      margin: 0 auto;
      border-radius: 16px;
      color: #000;
      font-weight: 600;
      &.active {
        background-color: #fba81d;
      }
    }
  }
}
.new-center-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 10px 0;
  padding: 0 5px;
  // margin: 0;
  li {
    width: 25%;
    float: left;
    text-align: center;
    a {
      background-color: #ebebeb;
      width: 95%;
      display: block;
      padding: 3px 5px;
      margin: 0 auto;
      border-radius: 16px;
      color: #000;
      font-weight: 600;
      &.active {
        background-color: #3db4e6;
        color: #fff;
      }
    }
  }
}

.tab-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 0;
  background-color: #3db4e6;
  border-radius: 15px;
  li {
    cursor: pointer;
    width: 33.33333333%;
    float: left;
    text-align: center;
    background-color: #3db4e6;
    color: #fff;
    padding: 5px 5px 12px;
    border-radius: 20px 20px 0 0;
    font-weight: 600;
    &.active {
      background-color: #00579c;
      color: #fff;
    }
  }
}

.total-content {
  background-color: #3db4e6;
  width: 100%;
  height: auto;
  float: left;
  padding-top: 40px;
  border-radius: 15px;
  margin-top: -26px;
  padding-bottom: 15px;
  font-family: Arial;
  ul {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin: 0;
    border-radius: 15px;
    li {
      cursor: pointer;
      width: 33.33333333%;
      float: left;
      text-align: center;
      background-color: #3db4e6;
      color: #00579c;
      // padding: 5px 5px 12px;
      // border-radius: 20px 20px 0 0;
      font-weight: 600;
      font-size: 30px;
      line-height: 1;
      &:nth-child(2),
      &:nth-child(3) {
        font-size: 34px;
      }
      &:nth-child(2) {
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
      }
      .pt-label {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        margin-left: 6px;
      }
    }
  }
}

.c-tab-content {
  margin-top: -10px;
  width: 100%;
  float: left;
  position: relative;
  border-radius: 15px;
  background-color: #00579c;
  padding-bottom: 25px;
  z-index: 10;
  .c-tab-item {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin: 0;
    padding: 8px 15px 15px;
    li {
      width: 33.33333333%;
      float: left;
      color: #fff;
      padding: 2px 10px 0 0;
      line-height: 1;
      margin-bottom: 10px;
      // border-radius: 20px 20px 0 0;
      // font-weight: 600;

      &:nth-child(1) {
        width: 100%;
      }

      &:nth-child(4),
      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(9),
      &:nth-child(10) {
        text-align: center;
      }

      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(9) {
        padding-left: 3px;
        padding-right: 3px;
      }

      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(9) {
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
      }

      .service-name {
        color: #f9aa1a;
        font-size: 15px;
        line-height: 1;
        font-weight: 600;
      }

      .target-text {
        display: block;
        font-size: 12px;
        font-weight: 600;
        margin-top: 2px;
      }
      .pt-count {
        color: #f9aa1a;
        font-weight: 600;
        font-size: 32px;
      }
      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(8) {
        line-height: 2;
        .service-name {
          color: #fcd603;
        }
      }
      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(9) {
        .pt-count {
          color: #fcd603;
        }
      }
      .money-count {
        color: #f9aa1a;
        font-weight: 600;
        font-size: 18px;
        small {
          font-weight: 600;
        }
      }
      .pt-label {
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        margin-left: 6px;
      }
    }
    &.pht {
      li {
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(9) {
          border-right: none;
        }
      }
    }
  }

  .cons-tab-item {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin: 0;
    padding: 15px 15px 15px;
    li {
      width: 33.33333333%;
      float: left;
      color: #fff;
      padding: 2px 10px 0 0;
      line-height: 1.2;
      // border-radius: 20px 20px 0 0;
      // font-weight: 600;

      &:nth-child(1) {
        line-height: 2;
      }

      &:nth-child(2) {
        text-align: center;
      }

      &:nth-child(2) {
        border-left: 1px solid #fff;
      }

      .service-name {
        color: #f9aa1a;
        font-size: 15px;
        line-height: 1;
        font-weight: 600;
      }
      .target-text {
        display: block;
        font-size: 12px;
        font-weight: 600;
        margin-top: 2px;
      }
      .pt-count {
        color: #fcd603;
        font-weight: 600;
        font-size: 32px;
      }
      .money-count {
        color: #f9aa1a;
        font-weight: 600;
        font-size: 18px;
        small {
          font-weight: 600;
        }
      }
      .pt-label {
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        margin-left: 6px;
      }
    }
  }

  .c-progressbar {
    width: 85%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    color: #fff;
    padding: 15px;
    margin: 5px 0;
    li {
      float: left;
      height: 15px;
      position: relative;
      border-right: 2px solid #00579c;
      &:last-child {
        border-right: none;
      }
      span {
        position: absolute;
        left: 0;
        font-weight: 600;
        top: -17px;
        left: 0;
        font-size: 12px;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}

.filter-list-new {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin: 0;
  li {
    width: 100px;
    float: right;
    text-align: center;
    button {
      color: #000;
    }
  }
}

.patient-selection-list {
  width: 100%;
  float: left;
  list-style-type: none;
  font-family: Arial;
  font-size: 16px;
  margin-top: 20px;

  li {
    width: 50%;
    float: left;
    text-align: center;
    margin-bottom: 20px;

    a {
      background-color: #ebebeb;
      padding: 8px 30px;
      border-radius: 20px;
      color: #000;
      font-family: Arial;

      &.active {
        background-color: #f9a81a;
        font-weight: 600;
      }
    }
  }
}

.tableFixHead {
  overflow: auto;
  height: 500px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.patient-income-table {
  height: 475px;
  overflow: scroll;
  // padding: 2px;
}
.popover {
  max-width: 360px;
}

.patient-card {
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  align-items: center;
  cursor: pointer;
  padding-right: 15px;
}

.patient-card div {
  margin-left: 15px;
  margin-bottom: 10px;
  width: 100%;
}

.patient-card div p {
  line-height: 20px;
}

.patient-card img {
  height: 54px;
  width: 54px;
  border-radius: 50%;
}
</style>
